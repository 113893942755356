import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegisterView from '../views/RegisterView'
import DownloadView from '../views/Download'
import LoginView from '../views/LoginView'
import WizardGuidView from '../views/WizardGuidView'
import Advertisement from '../views/Advertisement'
import AccountSetting from "../views/setting/AccountSetting";
import PersonView from "../views/setting/PersonView";
import MyCorp from "../views/setting/MyCorpView";
import Location from "../views/setting/LocationView";
import Storage from "../views/setting/MyStockView";
import QRCodeLogin from "../views/QRCodeLoginView"
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: QRCodeLogin
  },
  {
    path: '/advert',
    name: 'advert',
    component: Advertisement
  },

  {
    path: '/account',
    name: 'account',
    component: AccountSetting
  },
  {
    path: '/person',
    name: 'person',
    component: PersonView
  },

  {
    path: '/corp',
    name: 'corp',
    component: MyCorp
  },
  {
    path: '/location',
    name: 'location',
    component: Location
  },
  {
    path: '/storage',
    name: 'storage',
    component: Storage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },

  {
    path: '/wizard',
    name: 'wizard',
    component: WizardGuidView
  },
  {
    path: '/download',
    name: 'download',
    component: DownloadView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
