<template>

    <h1>{{ msg }}</h1>

<!--    <p>欢迎来到我们的网站，这里致力于为企业提供高效、便捷的小库管理解决方案。我们专注于小库软件开发，拥有成熟的PC端和微信小程序客户端，能够满足不同企业的多样化需求。
        我们的小库软件具备强大的功能特点，首先，它拥有独有的权限管理系统，通过精细化的权限设置，能够避免用户配置角色造成的低效操作，确保每位用户只能访问和操作其权限范围内的功能和数据，从而提高工作效率和数据安全性。
        </p>
    <p>其次，我们的小库软件还支持对物料进行全流程追溯。通过系统记录物料的入库、出库、领用等各个环节的信息，企业可以轻松追溯物料的使用情况和流向，有助于加强物料管理和控制，防止物料浪费和损失。
        </p>
    <p>在PC端，我们的小库软件界面友好，功能齐全，支持多用户同时操作，方便企业进行集中管理和协同工作。而微信小程序客户端则更加轻便灵活，可以随时随地进行库存查询和领用操作，极大提升了工作的便捷性。
        我们的团队具备丰富的行业经验和技术实力，能够为企业提供全方位的技术支持和服务。我们始终坚持以客户需求为导向，不断优化软件性能，确保系统稳定运行，为企业创造更大的价值。
         </p>
    <p>如果您正在寻找一款功能强大、操作便捷的小库管理软件，那么请不要犹豫，立即联系我们吧！我们期待与您携手合作，共创美好未来！</p>-->

</template>

<script>
    export default {
        name: "ProductDisplay",
        props: {
            msg: String
        }
    }
</script>

<style scoped>

</style>