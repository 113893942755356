<template>
    注册页面
    <el-text class="mx-1" type="info" >个人信息</el-text>
    <br>
    <el-input  v-model="phoneInput" style="width: 240px" placeholder="请输入电话信息" ></el-input>
    <br>
    <div class="inline-div">
        <el-input v-model="validInput" style="width: 240px" placeholder="请输入验证码" > </el-input>
    </div>
    <div class="inline-div">
        <el-button>获取验证码</el-button>
    </div>
    <br>
    <el-input  v-model="passwordInput" style="width: 240px" placeholder="输入账户密码" ></el-input>

       <br>

        <el-input  v-model="usernameInput" style="width: 240px" placeholder="请输入昵称" ></el-input>
         <br>







        <br>
        <el-text class="mx-1" type="info">公司信息</el-text>
        <el-input v-model="corpInput" style="width: 240px" placeholder="请输入公司名称" />

        <br>

        <el-input v-model="addrInput" style="width: 240px" placeholder="请输入公司地址 选填" />
    <br>
    <el-text class="mx-1" type="info">库房信息：注册后可更改，只能改名称</el-text>
    <br>
    <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="storageName" label="库房名称" width="80" />
        <el-table-column prop="storageNum" label="货架数量" width="80" />
        <el-table-column prop="floorNum" label="货架层数" width="80"  />
        <el-table-column prop="posNum" label="货架位数"  />
    </el-table>

    <br>

    <el-button type="success">提交</el-button>
</template>

<script   setup>
    import { ref } from 'vue'
    const usernameInput = ref('')
    const phoneInput = ref('')
    const corpInput = ref('')
    const validInput = ref('')
    const addrInput = ref('')
    const tableData = [
        {
            storageName: '电气库房',
            storageNum: '10',
            floorNum: '5',
            posNum:'10',
        },
        {
            storageName: '钳工库房',
            storageNum: '10',
            floorNum: '5',
            posNum:'10',
        },
        {
            storageName: '杂品库',
            storageNum: '10',
            floorNum: '5',
            posNum:'10',
        }
    ]
</script>

<style scoped>
    .inline-div {display: inline-block;}

</style>