<template>
    <div>
        下载页面
        <!-- 组件内容 -->
        <el-button @click="downloadBtn">{{btnLbl.descrip}}</el-button>
    </div>
</template>

<script>
    export default {
        name: 'DownloadView',
        // 组件数据
        data() {
            return {
                // ...
            btnLbl:{
                name: '下载桌面应用',
                descrip:'这是一个按钮'
            }
            };
        },
        // 方法等其他选项
        methods: {
            // ...
            downloadBtn(){
                console.log("下载");
                window.open("https://www.subude.com:8168/images/myFavorateApp.exe","_blank")
            }
        }
    }
</script>

<style scoped>
    /* 组件样式 */
</style>