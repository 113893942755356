<template>
    <button @click="wexinClick">二维码请求</button>
    <div id="weixin"  >
            微信二维码方案一
    </div>
<!--    <div>
        <img alt="Vue logo" src="../assets/ofhq96BNwu4Hfq7N1bPoVbWoUr8Y.jpeg">
    </div>-->
</template>

<script>

    export default {
        name: "QRCodeLoginView",
        data(){

        },
        created() {
            console.log("二维码登录页面初始化");
        },
        mounted(){
            console.log("二维码登录页面挂载");
/*          this.timer=window.setInterval(()=>{
              setTimeout(()=>{
                  console.log("轮训。。。。");
              },0)
          },3000)*/
        },
        destroyed(){
       //   window.clearInterval(this.timer);
            console.log("二维码登录页面销毁");
        },
        methods: {
            wexinClick(){
                //this.isShowForm=false;
                let _this=this;
                new WxLogin({
                    id:"weixin",
                    //wxd9c810f51e477f7e
                    appid:"wx1bddb61041eecb59",
                    scope:"snsapi_login",
                    //扫码成功后重定向的接口
                    redirect_uri:"https://www.inuojin.cn:8482/manage/wx/login/callback",
                    //state填写编码后的url 跳转后的路由地址
                  //  state:encodeURIComponent(),
                    href:""
                });
            }
        }

    }
</script>

<style scoped>

</style>