<template>
    个人信息
    <nav >
        <router-link to="/person">个人信息</router-link> |
        <router-link to="/corp">公司信息</router-link> |
        <router-link to="/location">地址信息</router-link> |
        <router-link to="/storage">库房信息</router-link>
    </nav>
    <router-view/>
    <div class="parent">
        *昵称______<input> <br>
        *性别________男：<input type="radio" checked="true">  | 女：<input type="radio"  > <br>
        &nbsp 生日______<input> <br>
        *电话信息___<input> <br>
        *邮箱信息___<input> <br>
        密码___  ___<input> <br>
        密码确认____<input> <br>

        上传头像
    </div>
</template>

<script>
    export default {
        name: "PersonView"
    }
</script>

<style scoped>
    .parent {
        text-align:left
    }
</style>