<template>
    地址信息
    <nav >
        <router-link to="/person">个人信息</router-link> |
        <router-link to="/corp">公司信息</router-link> |
        <router-link to="/location">地址信息</router-link> |
        <router-link to="/storage">库房信息</router-link>
    </nav>
    <router-view/>
</template>

<script>
    export default {
        name: "LocationView"
    }
</script>

<style scoped>

</style>