import { createApp } from 'vue'
import App from './App.vue'//引入组件 入口ui界面
import router from './router'//引入路由
import store from './store'
import Element from "element-plus"
import Vuex from 'vuex'
import * as ElementPlusIconsVue from "@element-plus/icons-vue"
const app=createApp(App);
for(const [key,component] of Object.entries(ElementPlusIconsVue)){
    app.component(key,component);
}
app.use(Element)
    .use(store)
    .use(router)
    .use(Vuex)
    .mount('#app')
