<template>
    库房信息
    <nav >
        <router-link to="/person">个人信息</router-link> |
        <router-link to="/corp">公司信息</router-link> |
        <router-link to="/location">地址信息</router-link> |
        <router-link to="/storage">库房信息</router-link>
    </nav>
    <router-view/>


    <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="storageName" label="库房名称" width="80" />
        <el-table-column prop="storageNum" label="货架数量" width="80" />
        <el-table-column prop="floorNum" label="货架层数" width="80"  />
        <el-table-column prop="posNum" label="货架位数"  />
    </el-table>
</template>

<script>
    export default {
        name: "MyStockView",
        data(){
            return{
                tableData : [
                    {
                        storageName: '电气库房',
                        storageNum: '10',
                        floorNum: '5',
                        posNum:'10',
                    },
                    {
                        storageName: '钳工库房',
                        storageNum: '10',
                        floorNum: '5',
                        posNum:'10',
                    },
                    {
                        storageName: '杂品库',
                        storageNum: '10',
                        floorNum: '5',
                        posNum:'10',
                    }
                ]
            }

        }
    }
</script>

<style scoped>

</style>