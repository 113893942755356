<template>
    公司信息
    <nav >
        <router-link to="/person">个人信息</router-link> |
        <router-link to="/corp">公司信息</router-link> |
        <router-link to="/location">地址信息</router-link> |
        <router-link to="/storage">库房信息</router-link>
    </nav>
    <router-view/>
    <div class="parent">
        *公司名称  &nbsp  &nbsp  &nbsp <input> <br>
        *本人与公司关系  &nbsp  &nbsp  &nbsp 法人 <input type="radio"> 员工 <input type="radio">  管理人员 <input type="radio">  <br>
        &nbsp 地址   下拉选择 三级 省  城市 级联选择 <br>
        详细地址
    </div>
</template>

<script>
    export default {
        name: "MyCorpView"
    }
</script>

<style scoped>
    .parent {
        text-align:left
    }
</style>