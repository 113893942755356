<template>
    新手向导---信息化初步
    <p>只需<span class="red">三步</span>帮你完成库房信息化</p>
    <p>第一步 ：<span class="yellow">注册</span> 并设置账号：现在开始<a href="#www.subude.com">注册</a><span class="green">你已经完成√</span></p>
    <p>第二步：pc端<span class="yellow">导入数据</span>,如何你没有下载pc端，请从这里<a href="#www.subude.com">下载</a></p>
    <div>
        <video controls muted  width="1000" height="680" v-if="videoLoaded" ref="video" autoplay playsinline>
            <source src="../assets/import-silent.mp4"   type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>

    <p>第三步：<span class="yellow">微信扫码</span>登录小程序查看，你的信息化已经完成。</p>
    <img alt="jumpMircroSoft" src="../assets/MircroApp.png">

    <p><a href="http://101.132.39.240/">更多操作向导</a></p>
</template>

<script>

    export default {
        name: "WizardGuidView",
        data() {
            return {
                videoLoaded: true
            };
        },
        methods:{
            openApp(){

            }
        },
        mounted() {
            this.$nextTick(() => {
                const video = this.$refs.video;
                if (video) {
                    video.addEventListener('loadeddata', () => {
                        this.videoLoaded = true;
                        video.play();
                    });
                }
            });
        }

    }
</script>

<style scoped>
    .red {
        font-size: 40px;
        color: red;
    }
    .yellow {
        font-size: 20px;
        color: red;
    }
    .green{
        color: green;
    }
</style>