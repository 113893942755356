<template>
    <nav >
        <router-link to="/person">个人信息</router-link> |
        <router-link to="/corp">公司信息</router-link> |
        <router-link to="/location">地址信息</router-link> |
        <router-link to="/storage">库房信息</router-link>
    </nav>
    <router-view/>

</template>

<script >
    export default {
        name: 'AccountSetting',
        // 组件数据
        data() {
            return {
                // ...
            };
        },
        // 方法等其他选项
        methods: {
            // ...
            handleClick(){
                console.log("测试");
            }
        }
    }
</script>

<style scoped>
    /* 组件样式 */

</style>