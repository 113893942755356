<template>
    登录页面（参照文心一言的登录）
    <el-input  v-model="username" style="width: 240px" placeholder="手机号吗" ></el-input>
    <br>
    <el-input  v-model="password" style="width: 240px" placeholder="验证码" ></el-input>
    <el-button>忘记密码</el-button>
    <br>
    <el-button>其他登录</el-button> |
    <el-button>微信扫码登录</el-button>

</template>

<script>
    export default {
        name: "LoginView"
    }
</script>

<style scoped>

</style>